// @ts-nocheck
import { all, takeEvery, put, fork, call, select } from "redux-saga/effects";
import {
  GET_CONTACTS,
  DELETE_CONTACTS,
  GET_SINGLE_CONTACTS,
  EDIT_SINGLE_CONTACTS,
  CHANGE_IMAGE,
  STORE_CONTACTS,
  GET_TAGS,
  IMPORT_CONTACTS,
  REMOVE_FROM_FUNNEL,
  SET_CONTACT_TO_FUNNEL,
  UNSUBSCRIBE_CONTACT,
  CONTACT_PARTIAL_UPDATE,
  ADD_CONTACTS_TO_CAMPAIGN,
  FILTER_CONTACTS,
  CREATE_INSURANCE,
  UPDATE_INSURANCE,
  DELETE_INSURANCE,
  CREATE_CONTACT_ADDRESS,
  UPDATE_CONTACT_ADDRESS,
  UPDATE_CONTACT_CARD,
  EXTERNAL_PLATFORM,
  DOWNLOAD_CONTACTS,
  ADD_TAGS_IN_BULK,
  REMOVE_TAGS_IN_BULK,
  UPDATE_CONTACT_STATUS,
  REMOVE_FUNNEL_FROM_CONTACT,
  GET_CONTACTS_FOR_MERGING,
  MERGE_CONTACTS,
  IMPORT_CONTACTS_RESULT,
} from "../constants/Contact";
import {
  setContacts,
  showMessage,
  getContacts as getAllContact,
  setSingleContact,
  changeImageSuccess,
  updateContacts,
  setTags,
  getTags as getAllTags,
  getContacts,
  deletContactsFromConversation,
  setInsurance,
  removeInsurance,
  editInsurance,
  getSingleContact,
  setContactsForMerging,
  removeMergedContact,
  updateMergedContact,
  setImportStatusOK,
  importStarted,
  setSingleContactLoading,
} from "../actions/Contact";
import { updateContactInfo } from "../actions/Conversation";
import { getFunnelContacts } from "../actions/Funnel";
import ContactService from "services/ContactService";
import {
  DEFAULT_ORDER_FIELD,
  NUMBER_PER_PAGE,
} from "constants/ContactConstant";
import { message } from "antd";
import { pushNewAmountSpent, pushNewStatusChange } from "./../actions/Activity";
import { ORDERING } from "constants/ApiConstant";

export function* getContactsSaga() {
  yield takeEvery(GET_CONTACTS, function* ({ payload }) {
    const { page, perPage, search, campaign, order, orderField } = payload;
    try {
      const contacts = yield call(
        ContactService.getContacts,
        page,
        perPage,
        search,
        campaign,
        order,
        orderField
      );
      const results = {
        data: contacts.data,
        order: order,
        orderField: orderField,
      };
      yield put(setContacts(results));
    } catch (err) {
      yield put(showMessage(err));
    }
  });
}

export function* saveContacts() {
  yield takeEvery(STORE_CONTACTS, function* ({ payload, messageSuccess }) {
    try {
      const contacts = yield call(ContactService.storeContacts, payload);
      yield put(updateContacts(contacts.data));
      yield put(getAllTags());
      yield put(showMessage(""));
      yield put(
        getContacts({
          page: 1,
          perPage: NUMBER_PER_PAGE,
          search: "",
          order: ORDERING.DESC,
          orderField: DEFAULT_ORDER_FIELD,
        })
      );
      message.success(messageSuccess, 3);
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data["non_field_errors"], 3);
      }

      if ("email" in err.response.data) {
        message.error(err.response.data["email"], 3);
      }

      if ("phone_number" in err.response.data) {
        message.error(err.response.data.phone_number[0], 3);
      }

      if ("name" in err.response.data) {
        message.error(err.response.data["name"], 3);
      }
    }
  });
}

export function* createInsurance() {
  yield takeEvery(
    CREATE_INSURANCE,
    function* ({ id, payload, successMessage }) {
      try {
        const response = yield call(
          ContactService.createInsurance,
          id,
          payload
        );
        yield put(setInsurance(response.data));
        message.success(successMessage, 3);
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export function* updateInsurance() {
  yield takeEvery(
    UPDATE_INSURANCE,
    function* ({ id, payload, successMessage }) {
      try {
        const response = yield call(
          ContactService.updateInsurance,
          id,
          payload
        );
        yield put(editInsurance(response.data));
        message.success(successMessage, 3);
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export function* createContactAddress() {
  yield takeEvery(
    CREATE_CONTACT_ADDRESS,
    function* ({ id, payload, successMessage }) {
      try {
        yield call(ContactService.createContactAddress, id, payload);
        message.success(successMessage, 3);
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export function* deleteInsurance() {
  yield takeEvery(
    DELETE_INSURANCE,
    function* ({ id, payload, successMessage }) {
      try {
        yield call(ContactService.deleteInsurance, id, payload);
        yield put(removeInsurance(id));
        message.success(successMessage, 3);
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export function* updateContactAddress() {
  yield takeEvery(UPDATE_CONTACT_ADDRESS, function* ({ id, payload }) {
    try {
      yield call(ContactService.updateContactAddress, id, payload);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* updateContactCard() {
  yield takeEvery(
    UPDATE_CONTACT_CARD,
    function* ({ id, payload, successMessage }) {
      const contactCardInfo = { ...payload?.cardData, ...payload?.contactData };
      try {
        const contact = yield call(
          ContactService.updateContactCard,
          id,
          contactCardInfo
        );
        const contactInfo = { ...contact.data, ...payload?.contactData };

        const contactData = {
          data: contactInfo,
        };
        yield put(setSingleContact(contactData));
        message.success(successMessage, 3);
      } catch (err) {
        const keyError = Object.keys(err.response.data)[0];
        if (keyError) {
          message.error(err.response.data[keyError], 3);
        }
      }
    }
  );
}

export function* updateExternalPlatform() {
  yield takeEvery(
    EXTERNAL_PLATFORM,
    function* ({ id, payload, successMessage }) {
      try {
        const contact = yield call(
          ContactService.updateExternalPlatform,
          id,
          payload
        );
        const contactData = { data: { ...contact?.data } };
        yield put(setSingleContact(contactData));

        message.success(successMessage, 3);
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export function* saveContactToFunnel() {
  yield takeEvery(SET_CONTACT_TO_FUNNEL, function* ({ payload, id }) {
    try {
      const contacts = yield call(ContactService.storeContacts, payload);
      yield put(updateContacts(contacts.data));
      yield put(getAllTags());
      yield put(showMessage(""));
      yield put(
        getFunnelContacts({
          page: 1,
          perPage: NUMBER_PER_PAGE,
          id: id,
          search: "",
        })
      );
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data["non_field_errors"], 3);
      }

      if ("email" in err.response.data) {
        message.error(err.response.data["email"], 3);
      }

      if ("phone_number" in err.response.data) {
        message.error([err.response.data.phone_number[0]], 3);
      }
    }
  });
}

export function* deleteContacts() {
  yield takeEvery(DELETE_CONTACTS, function* ({ payload }) {
    try {
      yield call(ContactService.deleteContacts, payload.selected_contacts);
      const data = {
        page: 1,
        perPage: NUMBER_PER_PAGE,
        campaign: payload.campaignId,
      };
      yield put(deletContactsFromConversation(payload.selected_contacts));
      yield put(getAllContact(data));
    } catch (err) {
      yield put(showMessage(err));
    }
  });
}

export function* getSingleContactSaga() {
  yield takeEvery(GET_SINGLE_CONTACTS, function* ({ payload }) {
    const id = payload;
    try {
      yield put(setSingleContactLoading());
      const contact = yield call(ContactService.getContact, id);
      yield put(setSingleContact(contact));
    } catch (err) {
      yield put(showMessage(err));
    }
  });
}

export function* editSingleContact() {
  yield takeEvery(EDIT_SINGLE_CONTACTS, function* ({ payload }) {
    const { id, data, messageSuccess, oldContactFunnel } = payload;
    try {
      const contact = yield call(ContactService.editContact, id, data);
      yield put(setSingleContact(contact));
      yield put(showMessage(""));
      yield put(pushNewStatusChange({ id, contact, oldContactFunnel }));
      yield put(updateContactInfo(contact));
      message.success(messageSuccess, 3);
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data["non_field_errors"], 3);
      }

      if ("phone_number" in err.response.data) {
        message.error([err.response.data.phone_number[0]], 3);
      }
      if ("email" in err.response.data) {
        message.error([err.response.data.email[0]], 3);
      }
    }
  });
}

export function* imageChange() {
  yield takeEvery(CHANGE_IMAGE, function* ({ payload }) {
    try {
      const contact = yield call(ContactService.changeImage, payload);
      yield put(changeImageSuccess(contact));
    } catch (err) {}
  });
}

export function* getTags() {
  yield takeEvery(GET_TAGS, function* () {
    try {
      const { data } = yield call(ContactService.getTags);
      yield put(setTags(data));
    } catch (err) {
      yield put(showMessage(err));
    }
  });
}

export function* contactsImport() {
  yield takeEvery(IMPORT_CONTACTS, function* ({ payload }) {
    try {
      yield call(ContactService.importContacts, payload);
    } catch (error) {
      yield put(setImportStatusOK(false));
      const errors = error.response.data;
      const errorKeys = Object.keys(errors);
      for (let key of errorKeys) {
        yield put(showMessage([`${errors[key]?.[0]} ${key}`]));
      }
      console.log(error.response.data);
    }
  });
}

export function* contactsImportResults() {
  yield takeEvery(IMPORT_CONTACTS_RESULT, function* ({ payload }) {
    try {
      const response = yield call(ContactService.importContactsResults);
      const importIsValid = yield select(
        (state) => state.contact?.importStatusOK
      );
      const { intervalId } = payload;

      if (!importIsValid) {
        clearInterval(intervalId);
        yield put(importStarted(false));
      }

      if (parseInt(response?.data?.imported)) {
        clearInterval(intervalId);

        yield put(importStarted(false));
        message.info(response?.data?.message);

        const data = { page: 1, perPage: NUMBER_PER_PAGE };
        yield put(getAllContact(data));
      }
    } catch (error) {
      const { intervalId } = payload;
      clearInterval(intervalId);

      yield put(importStarted(false));
      yield put(showMessage([error?.response?.data]));

      console.log(error.response.data);
    }
  });
}

function* removeFunnelSaga(payload) {
  yield call(ContactService.removeFromFunnel, payload.id, payload.funnel);
}

export function* removeContactFromFunnel() {
  yield takeEvery(REMOVE_FROM_FUNNEL, function* ({ payload }) {
    try {
      yield call(removeFunnelSaga, payload);
      yield getAllContact({ page: 1, perPage: NUMBER_PER_PAGE });
      yield put(
        getFunnelContacts({
          page: 1,
          perPage: NUMBER_PER_PAGE,
          id: payload.funnel,
          search: "",
        })
      );
    } catch (error) {
      yield put(showMessage(error));
    }
  });
}

export function* removeFunnelFromContact() {
  yield takeEvery(REMOVE_FUNNEL_FROM_CONTACT, function* ({ payload }) {
    try {
      yield call(removeFunnelSaga, payload);
      yield put(getSingleContact(payload.id));
    } catch (error) {
      yield put(showMessage(error));
    }
  });
}

export function* unsubscribeContactSaga() {
  yield takeEvery(
    UNSUBSCRIBE_CONTACT,
    function* ({ data, setIsUnsubscribe, successMessage }) {
      try {
        yield call(ContactService.unsubscribeContact, data);
        if (successMessage) {
          message.success(successMessage, 3);
        } else {
          yield put(setIsUnsubscribe(true));
        }
      } catch (err) {
        yield put(showMessage(err?.response?.data?.message));
      }
    }
  );
}

export function* contactPartialUpdate() {
  yield takeEvery(
    CONTACT_PARTIAL_UPDATE,
    function* ({ payload, successMessage }) {
      try {
        const { amount_spent, id } = payload;
        const contact = yield call(
          ContactService.partialUpdateContact,
          payload
        );
        yield put(setSingleContact(contact));
        if (amount_spent != 0)
          yield put(pushNewAmountSpent({ id, contact, amount: amount_spent }));
        message.success(successMessage, 3);
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export function* addContactsToCampaignSaga() {
  yield takeEvery(
    ADD_CONTACTS_TO_CAMPAIGN,
    function* ({ payload, campaignId }) {
      try {
        yield call(ContactService.addContactsToCampaign, payload, campaignId);

        const data = {
          page: 1,
          perPage: NUMBER_PER_PAGE,
        };
        yield put(getAllContact(data));
      } catch (err) {
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors[0], 3);
        }
      }
    }
  );
}

export function* getFilteredContacts() {
  yield takeEvery(FILTER_CONTACTS, function* ({ payload }) {
    const { page, perPage, search, campaign, order, orderField, data } =
      payload;
    try {
      const contacts = yield call(
        ContactService.filterContacts,
        page,
        perPage,
        search,
        campaign,
        order,
        orderField,
        data
      );
      const results = {
        data: contacts.data,
        order: order,
        orderField: orderField,
      };
      yield put(setContacts(results));
    } catch (err) {
      if ("non_field_errors" in err.response.data) {
        message.error(err.response.data.non_field_errors[0], 3);
      }
    }
  });
}

export function* downloadContacts() {
  yield takeEvery(DOWNLOAD_CONTACTS, function* ({ successMessage }) {
    try {
      yield call(ContactService.downloadContacts);
      message.success(successMessage, 3);
    } catch (err) {
      console.log(err);
    }
  });
}

export function* addTagsInBulk() {
  yield takeEvery(ADD_TAGS_IN_BULK, function* ({ payload }) {
    try {
      yield call(ContactService.addTagsInBulk, payload);
      if (!payload?.base_campaign_id) {
        const {
          current,
          order,
          orderField,
          search,
          campaignId,
          filterData,
          perPage,
        } = payload?.orderingInfo;
        const service = filterData ? "filterContacts" : "getContacts";
        const response = yield call(
          ContactService[service],
          current,
          perPage,
          search,
          campaignId,
          order,
          orderField,
          filterData
        );

        const results = {
          data: response.data,
          order: order,
          orderField: orderField,
        };
        yield put(setContacts(results));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* removeTagsInBulk() {
  yield takeEvery(REMOVE_TAGS_IN_BULK, function* ({ payload }) {
    try {
      yield call(ContactService.removeTagsInBulk, payload);
      if (!payload?.base_campaign_id) {
        const {
          current,
          order,
          orderField,
          search,
          campaignId,
          filterData,
          perPage,
        } = payload?.orderingInfo;
        const service = filterData ? "filterContacts" : "getContacts";
        const response = yield call(
          ContactService[service],
          current,
          perPage,
          search,
          campaignId,
          order,
          orderField,
          filterData
        );

        const results = {
          data: response.data,
          order: order,
          orderField: orderField,
        };
        yield put(setContacts(results));
      }
    } catch (err) {
      console.log(err);
    }
  });
}

export function* updateContactStatusSaga() {
  yield takeEvery(
    UPDATE_CONTACT_STATUS,
    function* ({ payload, successMessage, closeModal }) {
      try {
        const contact = yield call(ContactService.updateContactStatus, payload);
        yield put(setSingleContact(contact));
        message.success(successMessage, 3);
        closeModal();
      } catch (err) {
        console.log(err);
      }
    }
  );
}

export function* getMergeContactsDataSaga() {
  yield takeEvery(GET_CONTACTS_FOR_MERGING, function* ({ payload }) {
    try {
      const results = yield call(ContactService.getMergeContactsData, payload);
      yield put(setContactsForMerging(results?.data));
    } catch (err) {
      console.log(err);
    }
  });
}

export function* mergeContactsSaga() {
  yield takeEvery(
    MERGE_CONTACTS,
    function* ({ payload, closeModal, messageSuccess }) {
      try {
        const result = yield call(ContactService.mergeContacts, payload);
        message.success(messageSuccess, 3);
        closeModal();
        yield put(removeMergedContact(payload?.secondary_contact));
        yield put(updateMergedContact(result?.data));
      } catch (err) {
        console.log(err);
        if ("non_field_errors" in err.response.data) {
          message.error(err.response.data.non_field_errors[0], 3);
        }
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(getContactsSaga),
    fork(deleteContacts),
    fork(getSingleContactSaga),
    fork(editSingleContact),
    fork(imageChange),
    fork(saveContacts),
    fork(getTags),
    fork(contactsImport),
    fork(contactsImportResults),
    fork(removeContactFromFunnel),
    fork(saveContactToFunnel),
    fork(unsubscribeContactSaga),
    fork(contactPartialUpdate),
    fork(addContactsToCampaignSaga),
    fork(getFilteredContacts),
    fork(createInsurance),
    fork(updateInsurance),
    fork(deleteInsurance),
    fork(createContactAddress),
    fork(updateContactAddress),
    fork(updateContactCard),
    fork(updateExternalPlatform),
    fork(downloadContacts),
    fork(addTagsInBulk),
    fork(removeTagsInBulk),
    fork(updateContactStatusSaga),
    fork(removeFunnelFromContact),
    fork(getMergeContactsDataSaga),
    fork(mergeContactsSaga),
  ]);
}
