import ApiService from "./ApiService";
import { stringify } from "querystring";
import { format } from "util";

const ENDPOINTS = {
  GET_TRANSACTIONS: "transactions/",
  GET_TRANSACTION: "transactions/%s/",
  DELETE_TRANSACTION: "transactions/%s/",
  START_TRANSACTIONS_DOWNLOAD: "transactions/download-report/",
  TRANSACTIONS_DOWNLOAD_RESULTS: "transactions/report/",
  GET_MARKETING_REPORTING: "marketing-reporting/%s",
  GET_CAMPAIGN_PERFORMANCE: "best-campaign/",
  GET_AWARDS_STATS: "best-award/",
  CONNECT_CONTACT_AND_TRANSACTION: "transactions/connect_contact/",
  REMOVE_CONTACT_FROM_TRANSACTION: "transactions/remove_contact/",
  GET_TREATMENTS: "contacts/treatments/",
  PARTIAL_UPDATE_TRANSACTIONS: "transactions/%s/",
  CONNECT_APPOINTMENT: "transactions/%s/connect_appointment/",
  PRODUCTS_EXTRA: "transactions/products_extra/",
};

class ReportingService extends ApiService {
  getTransactions = (payload) => {
    return this.apiClient.get(
      `${ENDPOINTS.GET_TRANSACTIONS}?${stringify(payload)}`
    );
  };

  getSingleTransaction = ({ transactionId }) => {
    return this.apiClient.get(format(ENDPOINTS.GET_TRANSACTION, transactionId));
  };

  deleteTransaction = ({ transactionId }) => {
    return this.apiClient.delete(
      format(ENDPOINTS.DELETE_TRANSACTION, transactionId)
    );
  };

  startTransactionsDownload = (payload) => {
    return this.apiClient.get(
      `${ENDPOINTS.START_TRANSACTIONS_DOWNLOAD}?${stringify(payload)}`
    );
  };

  getTransactionsDownloadResults = () => {
    return this.apiClient.get(ENDPOINTS.TRANSACTIONS_DOWNLOAD_RESULTS);
  };

  getMarketingReporting = (filter) => {
    return this.apiClient.get(
      format(ENDPOINTS.GET_MARKETING_REPORTING, filter)
    );
  };

  getCampaignPefromance = () => {
    return this.apiClient.get(ENDPOINTS.GET_CAMPAIGN_PERFORMANCE);
  };

  getAwardsStats = () => {
    return this.apiClient.get(ENDPOINTS.GET_AWARDS_STATS);
  };

  connectContactAndTransaction = (payload) => {
    return this.apiClient.post(
      ENDPOINTS.CONNECT_CONTACT_AND_TRANSACTION,
      payload
    );
  };

  removeContactAndTransaction = (payload) => {
    return this.apiClient.post(
      ENDPOINTS.REMOVE_CONTACT_FROM_TRANSACTION,
      payload
    );
  };

  getTreatments = (payload) => {
    const { employees, product_name } = payload;

    if (!employees) {
      delete payload["employees"];
    }
    if (!product_name?.length) {
      delete payload["product_name"];
    }
    return this.apiClient.get(
      `${ENDPOINTS.GET_TREATMENTS}?${stringify(payload)}`
    );
  };

  partialUpdate = (data) => {
    return this.apiClient.patch(
      format(ENDPOINTS.PARTIAL_UPDATE_TRANSACTIONS, data?.id),
      data?.payload
    );
  };

  connectAppointment = (data) => {
    return this.apiClient.post(
      format(ENDPOINTS.CONNECT_APPOINTMENT, data?.id),
      data?.payload
    );
  };

  getProductsExtra = (payload) => {
    return this.apiClient.get(
      `${ENDPOINTS.PRODUCTS_EXTRA}?${stringify(payload)}`
    );
  };
}

export default new ReportingService();
