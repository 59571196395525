import React from "react";
import { Tag } from "antd";
import { BETA } from "constants/BetaTagConstant";

const BetaTag = () => {
  return (
    <Tag className="beta-tag" color="success">
      {BETA}
    </Tag>
  );
};

export default BetaTag;
