import {
  DELETE_APPOINTMENT,
  DELETE_APPOINTMENT_SUCCESS,
  EDIT_APPOINTMENT,
  EDIT_APPOINTMENT_SUCCESS,
  GET_APPOINTMENTS,
  GET_APPOINTMENTS_SUCCESS,
  NEW_APPOINTMENT,
  NEW_APPOINTMENT_SUCCESS,
  SHOW_MESSAGE,
  GET_CALENDAR,
  GET_CALENDAR_SUCCESS,
  GET_PREVIOUS_AND_NEXT_APPOITMENT,
  PREVIOUS_AND_NEXT_SUCCESS,
  CHECK_OR_CREATE_NEW_APPOINTMENT,
  CHECK_OR_EDIT_APPOINTMENT,
  SET_APPOINTMENT_TAB,
} from "../constants/Appointment";

export const getPreviousAndNextAppoirment = (payload) => ({
  type: GET_PREVIOUS_AND_NEXT_APPOITMENT,
  payload,
});

export const previousAndNextSuccess = (payload) => ({
  type: PREVIOUS_AND_NEXT_SUCCESS,
  payload,
});

export const newAppointment = (payload) => ({
  type: NEW_APPOINTMENT,
  payload,
});

export const newAppointmentSuccess = (payload) => ({
  type: NEW_APPOINTMENT_SUCCESS,
  payload,
});

export const getAppointments = (payload, month = null, year = null) => ({
  type: GET_APPOINTMENTS,
  payload,
  month,
  year,
});

export const getAppointmentsSuccess = (payload) => ({
  type: GET_APPOINTMENTS_SUCCESS,
  payload,
});

export const showMessage = (payload) => ({
  type: SHOW_MESSAGE,
  payload,
});

export const deleteAppointment = (payload) => ({
  type: DELETE_APPOINTMENT,
  payload,
});

export const deleteAppointmentSuccess = (payload) => ({
  type: DELETE_APPOINTMENT_SUCCESS,
  payload,
});

export const editAppointment = (payload) => ({
  type: EDIT_APPOINTMENT,
  payload,
});

export const editAppointmentSuccess = (payload) => ({
  type: EDIT_APPOINTMENT_SUCCESS,
  payload,
});

export const getCalendar = (month = null, year = null) => ({
  type: GET_CALENDAR,
  month,
  year,
});

export const getCalendarSuccess = (payload) => ({
  type: GET_CALENDAR_SUCCESS,
  payload,
});

export const checkOrCreateNewAppointment = (payload) => ({
  type: CHECK_OR_CREATE_NEW_APPOINTMENT,
  payload,
});

export const checkOrEditeAppointment = (payload) => ({
  type: CHECK_OR_EDIT_APPOINTMENT,
  payload,
});

export const setAppointmentTab = (payload) => ({
  type: SET_APPOINTMENT_TAB,
  payload,
});
